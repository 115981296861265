<template>
  <div class="pointer-events-none h-100 py-2 d-flex justify-content-center align-items-center"
       :class="{'justify-content-start': !align_right, 'justify-content-end': align_right,'show-background': show_background}">
    <div class="flex-grow-1">
      <div class="form-row justify-content-center">
        <Card v-for="card in cards" :key="card.id" :card="card" :direction="direction"></Card>
        <div v-if="more_cards && !$store.state.screenshot_mode"
             :class="{'col-3': direction === 'horizontal', 'col-6': direction === 'vertical'}">
          <div class="add-card shadow-sm rounded" @click="addCard">
            <div class="h-100 d-flex align-items-center justify-content-center display-4">
              +
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "./Card";

export default {
  name: "Cardlist",
  props: [
    'card_type',
    'align_right',
    'direction'
  ],
  components: {
    Card
  },
  methods: {
    addCard() {
      if (this.more_cards) {
        let data = {
          type: this.card_type,
          content: this.$root.translate('edit_me')
        }
        this.$store.commit('addCard', data)
      }
    }
  },
  computed: {
    cards() {
      return this.$store.state.canvas.cards.filter(card => card.type === this.card_type)
    },
    more_cards() {
      return this.cards.length < this.max_cards
    },
    max_cards() {
      if (this.direction === 'vertical') {
        return 6
      }
      return 7
    },
    show_background() {
      return this.$store.state.canvas.show_background
    },
  }
}
</script>

<style>
.pointer-events-none {
  pointer-events: none;
}

.card-width {
  width: 100px;
}

.card-list-l1, .card-list-l2 {
  margin-left: -33.33333%;
}

.card-list-r1, .card-list-r2 {
  margin-right: -33.33333%;
}

.add-card {
  user-select: none;
  max-width: 6rem;
  height: 6rem;
  border: dashed 2px #B5B5B5;
  background: rgba(0, 0, 0, 0.05)
}

.show-background .editable-card:not(.editing), .show-background .add-card {
  opacity: .9;
}

.add-card {
  pointer-events: all !important;
}
</style>