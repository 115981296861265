<template>
  <div>
    <div class="border-bottom py-3 no-print">
      <div class="container text-secondary">
        <div class="d-flex justify-content-between align-items-center fa-2x">
          <img src="https://modelmatig.nl/wp-content/uploads/2017/12/Modelmatig-Logo-2018.svg" alt="Modelmatig" width="60" height="30">
          <div class="d-flex align-items-center flex-grow-1">
            <div class="flex-grow-1"></div>
            <div v-if="$store.state.saving" class="d-flex align-items-center justify-content-center mr-3">
              <i class="fal fa-circle-notch fa-spin"></i>
            </div>
            <div v-if="$store.state.saved" class="d-flex justify-content-end px-3 flex-grow-2">
              <div class="route-width">
                <div class="input-group">
                  <input ref="canvasRoute" type="text" class="form-control right-aligned" :value="canvas_route">
                  <div class="input-group-append">
                  <span class="input-group-text pointer" @click="copyRoute">
                    <i v-if="route_copied" class="fas fa-check text-success"/>
                    <i v-else class="fas fa-clone"/>
                  </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div @click="$store.commit('toggleScreenshotMode')" class="pointer mr-3 position-relative d-flex align-items-center justify-content-center">
                <div v-if="$store.state.screenshot_mode" class="position-absolute d-flex align-items-center justify-content-center">
                  <i class="fal fa-slash"></i>
                </div>
                <div>
                  <i class="fal fa-camera"></i>
                </div>
              </div>
              <div @click="$store.commit('toggleSettings')" class="pointer">
                <i class="fal fa-cog"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pt-3">
      <div class="h2">
        {{ $root.translate('canvas_from') }}
      </div>
      <div>
        <input @focusout="$root.saveCanvas()" ref="companyName" class="company-input" type="text" v-model="company">
      </div>
    </div>
    <Sidebar></Sidebar>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";

export default {
  name:       "Header",
  components: {
    Sidebar
  },
  data() {
    return {
      route_copied: false
    }
  },
  computed: {
    company: {
      get() {
        if (this.$store.state.canvas.company) {
          return this.$store.state.canvas.company
        }
        return this.$root.translate('company')
      },
      set(value) {
        this.$store.commit('setCompany', value)
      }
    },
    show_settings() {
      return this.$store.state.show_settings
    },
    canvas_slug_set() {
      return !!this.$store.state.canvas.slug
    },
    canvas_route() {
      return window.location.protocol + '//' + window.location.hostname + '/canvas/' + this.$store.state.canvas.slug
    },
  },
  methods:  {
    copyRoute() {
      this.route_copied = true
      this.$refs.canvasRoute.select()
      document.execCommand("copy")

      setTimeout(() => {
        this.route_copied = false
      }, 3000)
    }
  }
}
</script>

<style scoped>
input.right-aligned:not(:focus) {
  direction: rtl;
  text-align: left;
  unicode-bidi: plaintext;
  text-overflow: ellipsis;
}
</style>