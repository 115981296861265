<template>
  <div id="canvas" ref="canvas" class="cards p-2 px-lg-0 py-lg-5" :class="'cards-' + style_picked">
    <div class="container">
      <div class="canvas canvas-height">
        <div class="form-row h-100 canvas-height ml-n3 mr-n3">
          <div class="col-md-6 left-background">
            <div class="form-row h-100 canvas-height">
              <div class="col-ver">
                <CardList :card_type="'VP_PRODUCT'" :align_right="false" :direction="'vertical'"
                          :class="'card-list-ltb'"/>
              </div>
              <div class="col-hor">
                <div class="h-50">
                  <CardList :card_type="'VP_GAIN'" :align_right="false" :direction="'horizontal'"
                            :class="'card-list-l1'"/>
                </div>
                <div class="h-50">
                  <CardList :card_type="'VP_PAIN'" :align_right="false" :direction="'horizontal'"
                            :class="'card-list-l2'"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 right-background">
            <div class="form-row h-100 canvas-height">
              <div class="col-hor">
                <div class="h-50">
                  <CardList :card_type="'CP_GAIN'" :align_right="true" :direction="'horizontal'"
                            :class="'card-list-r1'"/>
                </div>
                <div class="h-50">
                  <CardList :card_type="'CP_PAIN'" :align_right="true" :direction="'horizontal'"
                            :class="'card-list-r2'"/>
                </div>
              </div>
              <div class="col-ver">
                <CardList :card_type="'CP_JOBS'" :align_right="true" :direction="'vertical'" :class="'card-list-rtb'"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardList from "@/components/CardList"
import {getCanvas} from "@/utils/server-connect";

export default {
  name: "ValuePropositionCanvas",
  components: {
    CardList
  },
  computed: {
    style_picked() {
      return this.$store.state.canvas.style_picked
    },
    editing() {
      return this.$store.state.editing
    }
  },
  mounted() {
    let data = {}
    data.slug = this.$router.currentRoute.params.slug

    let canvas_type = 'value_proposition';

    getCanvas(data).then((response) => {
      response.data.type = canvas_type
      this.$store.commit('setCanvasData', response.data)
    })
  },
}
</script>

<style scoped>
@media screen and (min-width: 768px) {

  .canvas {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../assets/waardepropostie-canvas-modelmatig.svg');
    position: relative;
  }

}

@media screen and (max-width: 767.98px) {
  .left-background {
    background-position: left;
    background-repeat: no-repeat;
    background-image: url('../assets/waardepropostie-canvas-modelmatig.svg');
    background-size: 200%;
  }

  .right-background {
    background-position: right;
    background-repeat: no-repeat;
    background-image: url('../assets/waardepropostie-canvas-modelmatig.svg');
    background-size: 200%;
  }
}
</style>