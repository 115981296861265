<template>
  <div class="card-col mb-2" :class="{'col-3': direction === 'horizontal', 'col-6': direction === 'vertical'}">
    <div class="editable-card position-relative" :class="{'editing': editing}">
      <div class="box shadow2 d-flex flex-column" :class="card_background">
        <div class="justify-content-between px-3 pt-1 text-secondary" :class="{'d-flex': editing, 'd-none': !card.editing}">
          <div class="card-action fa-2x card-discard" @click="discardInput" :title="$root.translate('discard_changes')">
            <i class="fal fa-times"></i>
          </div>
          <div class="card-action fa-2x card-save" @click="saveInput" :title="$root.translate('save_changes')">
            <i class="fal fa-check"></i>
          </div>
        </div>
        <div class="card-options position-absolute w-100 rounded bottom-0 left-0" v-if="!editing && !$store.state.screenshot_mode">
          <div class="d-flex justify-content-between align-items-center px-2">
            <div class="btn btn-sm pointer" @click="removeCard" :title="$root.translate('delete_card')">
              <i class="fal fa-trash-alt"></i>
            </div>
            <div class="btn btn-sm pointer" v-if="!editing && !editing_cards" @click="editCard">
              <i class="fal fa-pencil-alt"></i>
            </div>
          </div>
        </div>
        <div v-if="!editing" class="card-content font-weight-bolder flex-grow-1 p-1 d-flex align-items-center justify-content-center">
          <div class="text-center">
            {{ card.content }}
          </div>
        </div>
        <textarea v-else ref="contentTextArea" name="" id="" class="flex-grow-1 text-field p-3" v-model="card.content"></textarea>
        <div v-if="!editing" class="position-absolute top-0 right-0 rounded-circle shadow-sm bg-white card-icon d-flex align-items-center justify-content-center pointer" :class="{'checked': card.checked}" @click="toggleCardCheck">
          <i v-if="card.checked" class="fas fa-check text-success"></i>
          <i v-else class="fas fa-times text-danger"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {removeCard} from "@/utils/server-connect";

export default {
  name:     "Card",
  props:    [
    'card',
    'direction',
  ],
  data:     function () {
    return {
      old_content: '',
      editing:     false
    }
  },
  methods:  {
    discardInput: function () {
      this.card.content = this.old_content
      this.old_content  = ''
      this.editing      = false
      this.$store.commit('canvasEdited')
    },
    saveInput:    function () {
      this.editing = false
      this.$store.commit('canvasEdited')

      this.$root.saveCanvas()
    },
    editCard:     function () {
      if (!this.editing_cards) {
        this.old_content = this.card.content
        this.editing     = true
        this.$store.commit('canvasEditing')
      }
      setTimeout(() => {
        if (this.old_content === this.$root.translate('edit_me')) {
          this.$refs.contentTextArea.select()
        } else {
          this.$refs.contentTextArea.focus()
        }
      }, 100)
    },
    removeCard:   function () {
      this.$store.commit('canvasSaving')
      this.$store.commit('removeCard', this.card)
      let data = {
        card: this.card,
        slug: this.$store.state.canvas.slug
      }
      removeCard(data).then(() => {
        this.$store.commit('canvasSaved')
      })
    },
    toggleCardCheck() {
      this.card.checked = !this.card.checked
      this.$root.saveCanvas()
    }
  },
  computed: {
    editing_cards() {
      return this.$store.state.editing
    },
    card_background() {
      let bg_class
      switch (this.card.type) {
        case 'VP_PRODUCT':
          bg_class = 'box2'
          break;
        case 'VP_GAIN':
          bg_class = 'box3'
          break;
        case 'VP_PAIN':
          bg_class = 'box3'
          break;
        case 'CP_JOBS':
          bg_class = 'box2'
          break;
        case 'CP_PAIN':
          bg_class = 'box1'
          break;
        case 'CP_GAIN':
          bg_class = 'box3'
          break;
      }
      return bg_class
    }
  }
}
</script>

<style>
.card-list-l1 .form-row > div:nth-child(5) {
  margin-left: 25%;
}

.card-list-l2 .form-row > div:nth-child(1) {
  margin-left: 25%;
}

.card-list-r1 .form-row > div:last-child {
  margin-right: 25%;
}

.card-list-r2 .form-row > div:nth-child(3) {
  margin-right: 25%;
}

.card-list-ltb .form-row > div:first-child, .card-list-ltb .form-row > div:nth-child(6) {
  margin-right: 50%;
}

.card-list-rtb .form-row > div:first-child, .card-list-rtb .form-row > div:nth-child(6) {
  margin-left: 50%;
}

.text-field {
  border: none;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  background: none;
  margin: 0;
  padding: 0;
  height: 100%;
  font-weight: 500;
  outline: none;
  resize: none;
  font-size: 2rem;
}

.card-checked {
  top: -1rem;
  right: -1rem;
  width: 2rem;
  height: 2rem;
  border: 4px solid #000000;
  background-color: #FFFFFF;
}

.card-action {
  cursor: pointer;
}

.editable-card {
  height: 6rem;
  max-width: 100%;
}

.card-save:hover {
  color: #28A745 !important;
}

.card-discard:hover {
  color: #DC3545 !important;
}

.editable-card:hover .card-options {
  display: block;
  z-index: 10;
}

.editable-card.editing {
  height: 100%;
  width: 100%;
  max-width: 18rem;
  max-height: 18rem;
  position: fixed !important;
  top: 10%;
  left: calc(50% - 9rem);
  z-index: 20;
}

.editable-card.editing:before {
  content: "";
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.editable-card .edit {
  display: none;
}

.editable-card:hover .edit {
  display: flex;
  cursor: pointer;
}

.card-content {
  line-height: 1.2;
}

.card-checked:not(.d-block) {
  display: block !important;
  opacity: .4;
}

.card-checked {
  cursor: pointer;
  background-image: url("../assets/icons/check-solid.svg");
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
  z-index: 3;
}

.card-checked:hover {
  opacity: 1 !important;
}

.box {
  background-color: white;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  position: relative;
}

.box h3 {
  font-family: 'Didact Gothic', sans-serif;
  font-weight: normal;
  text-align: center;
  padding-top: 60px;
  color: #FFF;
}

.box1 {
  background-color: #EBA39E;
}

.box2 {
  background-color: #EDE89A;
}

.box3 {
  background-color: #9EEBBF;
}

.cards-1 .shadow2 {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 0, 0, 0.1) inset;
}

.cards-1 .shadow2:before {
  position: absolute;
  content: "";
  width: 80%;
  top: 20px;
  bottom: 12px;
  left: 12px;
  z-index: -1;
  transform: rotate(-4deg);
}

.editing .box:before {
  content: none;
}

.editing .box {
  box-shadow: 0 60px 30px 20px rgba(0, 0, 0, .3) !important;
}

.cards-1 .box1.shadow2:before {
  background-color: #9B7468;
  box-shadow: 0 18px 6px 0 #9B7468;
}


.cards-1 .box2.shadow2:before {
  background-color: #9F8641;
  box-shadow: 0 18px 6px 0 #9F8641;
}


.cards-1 .box3.shadow2:before {
  background-color: #548E7F;
  box-shadow: 0 18px 6px 0 #548E7F;
}

.card-icon {
  width: 30px;
  height: 30px;
  margin-right: -8px;
  margin-top: -8px;
  border: 4px solid #DC3545;
  /*z-index: 3;*/
}

.card-icon.checked {
  border-color: #28A745;
}

.editable-card {
  pointer-events: all !important;
}
</style>