export const initialState = {
    canvas:          {
        slug:            null,
        cards:           [],
        locale:          'nl_nl',
        type:            null,
        show_background: false,
        style_picked:    1,
        company:         '',
        auto_save:       true,
    },
    show_settings:   false,
    screenshot_mode: false,
    saving:          false,
    saved:           false,
    editing:         false,
}