<template>
  <div id="sidebar">
    <div id="sidebar-overlay" :class="{'show': show_settings}" @click="toggleSettings" class="position-absolute top-0 left-0 no-print"></div>
    <div id="settings" class="position-absolute bg-white shadow top-0 no-print" :class="{'show-settings': show_settings}">
      <div class="p-3 bg-light d-flex justify-content-between align-items-center fa-2x">
        <div class="d-flex align-items-baseline mr-3">
          {{ $root.translate('settings') }}
        </div>
        <div class="d-flex align-items-baseline pointer" @click="toggleSettings">
          <i class="fal fa-times"></i>
        </div>
      </div>
      <div class="p-3">
        <div class="h3">
          {{ $root.translate('background') }}
        </div>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="show_background" v-model="show_background">
          <label class="custom-control-label" for="show_background">{{ $root.translate('show_background_overlay') }}</label>
        </div>
        <div class="h3 mt-3">
          {{ $root.translate('choose_a_style') }}
        </div>
        <div v-for="style in 2" :key="style" class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" name="style" :value="style" :id="'style_' + style" v-model="style_picked">
          <label class="custom-control-label" :for="'style_' + style">{{ $root.translate('style_' + style) }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:     "Sidebar",
  computed: {
    style_picked:    {
      get() {
        return this.$store.state.canvas.style_picked
      },
      set(value) {
        this.$store.commit('updateStyle', value)
      }
    },
    show_background: {
      get() {
        return this.$store.state.canvas.show_background
      },
      set(value) {
        this.$store.commit('toggleBackground', !!value)
      }
    },
    editing() {
      return this.$store.state.editing
    },
    show_settings() {
      return this.$store.state.show_settings
    }
  },
  methods:  {
    toggleSettings() {
      this.$store.commit('toggleSettings')
    }
  }
}
</script>

<style scoped>

</style>