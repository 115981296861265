export const nl_nl = {
    edit_me: 'Bewerk mij!',
    settings: 'Instellingen',
    background: 'Achtergrond',
    show_background_overlay: 'Toon achtergrond',
    choose_a_style: 'Kies een style',
    style_1: 'Plaknotitie',
    style_2: 'Plat',
    style_3: 'Custom',
    delete_card: 'Verwijderen',
    save_changes: 'Wijzigingen opslaan',
    discard_changes: 'Wijzigingen annuleren',
    canvas_from: 'Canvas van:',
    company: 'Mijn bedrijf'
}

export const en_gb = {
    edit_me: 'Edit me!',
    settings: 'Settings',
    background: 'Background',
    show_background_overlay: 'Show background overlay',
    choose_a_style: 'Choose a style',
    style_1: 'Sticky note',
    style_2: 'Flat',
    style_3: 'Custom',
    delete_card: 'Delete',
    save_changes: 'Save changes',
    discard_changes: 'Discard changes',
    canvas_from: 'Canvas from:',
    company: 'My company'
}