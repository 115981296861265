import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import VueRouter from "vue-router";
import {options} from "./vuex/options"
import {nl_nl, en_gb} from "@/utils/translations";
import {storeCanvas} from "@/utils/server-connect";
import router from './router'

Vue.use(Vuex)
Vue.use(VueRouter)

Vue.config.productionTip = false

const store  = new Vuex.Store(options)

new Vue({
    store,

    data:   {
        translations: nl_nl,
        currentRoute: window.location.pathname
    },

    methods: {
        translate(key, parameters) {
            if (this.translations) {
                let translation = this.translations[key]
                if (parameters) {
                    Object.entries(parameters).forEach(([key, value]) => {
                        translation = translation.replace(':' + key, value)
                    })
                }
                return translation;
            }

            return key;
        },
        saveCanvas() {
            let payload = this.$store.state
            this.$store.commit('canvasSaving')
            storeCanvas(payload).then(() => {
                this.$store.commit('canvasSaved')
            })
        }
    },

    router,
    render:  h => h(App)
}).$mount('#app')
