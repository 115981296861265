import axios from "axios";

let base_url = 'https://api.modelmatig.nl/'
if(process.env.NODE_ENV === 'development') {
    base_url = 'http://localhost:80/'
}

const axiosClient = axios.create({
    baseURL: base_url,
    headers: {
        'Access-Control-Allow-Origin': '*'
    }
})

export const storeCanvas = (data) => axiosClient.post('store', data)

export const getCanvas = (data) => axiosClient.post('canvas', data)

export const removeCard = (data) => axiosClient.post('remove-card', data)