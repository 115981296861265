import {initialState} from "./initial-state";

export const options = {
    state:     initialState,
    mutations: {
        setCanvasData(state, data) {
            if (data.canvas) {
                state.canvas = data.canvas
                state.saved  = true
            } else if (data.slug) {
                state.canvas.slug = data.slug
            }
            state.canvas.type = data.type
        },
        addCard(state, data) {
            let order = state.canvas.cards.filter(card => card.type === data.type).length + 1
            state.canvas.cards.push({
                type:    data.type,
                content: data.content,
                order:   order,
                checked: false
            })
        },
        removeCard(state, card_data) {
            var card_index = ''
            state.canvas.cards.some((card, index) => {
                if (card.type === card_data.type && card.order === card_data.order) {
                    card_index = index
                }
            })
            if (card_index !== '') {
                state.canvas.cards.splice(card_index, 1)
            }
        },
        updateStyle(state, value) {
            state.canvas.style_picked = value
        },
        setCompany(state, value) {
            state.canvas.company = value
        },
        toggleSettings(state) {
            state.show_settings = !state.show_settings
        },
        toggleBackground(state) {
            state.canvas.show_background = !state.canvas.show_background
        },
        canvasSaving(state) {
            state.saving = true
        },
        canvasSaved(state) {
            state.saving = false
            state.saved  = true
        },
        canvasEditing(state) {
            state.editing = true
        },
        canvasEdited(state) {
            state.editing = false
        },
        toggleScreenshotMode(state) {
            state.screenshot_mode = !state.screenshot_mode
        }
    }
}