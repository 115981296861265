<template>
  <div id="app" class="overflow-hidden position-relative">
    <Header/>
    <router-view></router-view>
  </div>
</template>

<script>
import Header from "@/components/Header";

export default {
  name:       'App',
  components: {
    Header
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2C3E50;
  min-height: 100vh;
}

body {
  overflow-x: hidden;
  min-height: 100vh;
}

@media print {
  .no-print {
    display: none !important;
  }
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.pointer {
  cursor: pointer;
}

.add-card {
  cursor: pointer !important;
}

#settings {
  min-width: 250px;
  z-index: 10;
  height: 100%;
  transition: margin-right .3s ease-in-out;
  right: 0;
  margin-right: -110%
}

#settings.show-settings {
  margin-right: 0 !important;
}

#sidebar-overlay, #cards-overlay {
  background: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  z-index: 9;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  pointer-events: none;
}

#sidebar-overlay.show, #cards-overlay.show {
  pointer-events: unset !important;
  opacity: 1;
}

.canvas-height {
  min-height: 100vw;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .canvas-height {
    min-height: 530px;
  }

  .canvas:after {
    min-height: 530px;
  }
}

@media screen and (min-width: 992px) {
  .canvas-height {
    min-height: 530px;
  }

  .canvas:after {
    min-height: 530px;
  }
}

.col-ver {
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%;
}

.col-hor {
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%;
}

.company-input {
  border: none;
  background: none;
  outline: none;
  font-size: 1.5rem;
}
</style>
